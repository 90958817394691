/* Roboto */
@font-face {
  font-family: Roboto;
  font-weight: 300;
  font-display: swap;
  src: url('roboto/Roboto-Light.ttf') format('truetype');
}
@font-face {
  font-family: Roboto;
  font-weight: 400;
  font-display: swap;
  src: url('roboto/Roboto-Regular.ttf') format('truetype');
}
@font-face {
  font-family: Roboto;
  font-weight: 500;
  font-display: swap;
  src: url('roboto/Roboto-Medium.ttf') format('truetype');
}
@font-face {
  font-family: Roboto;
  font-weight: 700;
  font-display: swap;
  src: url('roboto/Roboto-Bold.ttf') format('truetype');
}

/* Roboto Slab */
@font-face {
  font-family: Roboto-Slab;
  font-weight: 400;
  font-display: swap;
  src: url('roboto-slab/Roboto-Slab-Regular.ttf') format('truetype');
}
@font-face {
  font-family: Roboto-Slab;
  font-weight: 500;
  font-display: swap;
  src: url('roboto-slab/Roboto-Slab-Medium.ttf') format('truetype');
}
@font-face {
  font-family: Roboto-Slab;
  font-weight: 700;
  font-display: swap;
  src: url('roboto-slab/Roboto-Slab-Bold.ttf') format('truetype');
}

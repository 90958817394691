@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import 'assets/fonts/fonts.css';
  body {
    @apply font-roboto font-normal text-gray-900 bg-gray-50 leading-normal tracking-wide;
  }
}
@layer utilities {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/* Carousel */
.carousel__next-btn {
  @apply absolute top-1/3 right-0 z-50 mr-2;
}
.carousel__prev-btn {
  @apply absolute top-1/3 left-0 z-50 ml-2;
}
.carousel__prev-btn,
.carousel__next-btn {
  @apply rounded p-1 bg-blue-100 cursor-pointer;
}
.swiper-button-disabled {
  @apply opacity-30 cursor-default !important;
}

/*  Loader icon */
.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
}
.loading-blocks {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loading-blocks .block {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  animation: loading-blocks 0.6s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  @apply bg-blue-500;
}
.loading-blocks .block:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.loading-blocks .block:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.loading-blocks .block:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes loading-blocks {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
.loading-blocks__text {
  font-size: 1.4rem;
  font-weight: 700;
}

/** view transition effect **/
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}
